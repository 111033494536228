import { FunctionComponent, HTMLAttributes } from 'react';

const IconCheck: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    viewBox="0 0 13 10"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M12 0.5L4.125 9.5L0.75 6.125"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCheck;
