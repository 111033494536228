import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

const isMobile = atom(false);
const checkMobile = atom(true);

const useIsMobile = () => {
  const [mobile, setIsMobile] = useAtom(isMobile);

  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth < 1024) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsMobile]);

  return mobile;
};

export const useCheckMobile = () => {
  const [mobile, setIsMobile] = useAtom(checkMobile);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth >= 1024) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsMobile]);

  return mobile;
};

export default useIsMobile;
