import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, ReactNode } from 'react';

import formatElements from '../../../utils/formatElements';
import BrandShape from '../../ui/icons/brand-shape';

import classes from './about-us.module.css';
import { useAppContext } from '../../providers/app-context';
import Curve from '../../ui/curve';

const AboutUs: FunctionComponent = () => {
  const { t } = useTranslation();
  const { businessValues } = useAppContext();
  const content = t(
    'home:about-us.content',
    {
      totalStockAvailable: businessValues.totalStockAvailable.defaultValue,
    },
    { returnObjects: true }
  ) as unknown as Array<{ title: string; description: string }>;
  return (
    <section>
      <Curve className="h-4 lg:h-6 scale-y-invert lg:scale-x-invert text-neutral-10 " />
      <section className="py-6 lg:py-12 px-6 text-neutral-80 bg-neutral-10">
        <div className="container m-auto bg-neutral-10">
          <h2
            className={cx(
              classes.title,
              'text-bparts-100 font-bold text-2xl text-center px-6 mb-6'
            )}
          >
            {t('home:about-us.title')}
          </h2>
          <ol
            className={cx(
              classes.list,
              'lg:grid lg:grid-cols-3 lg:gap-6'
            )}
          >
            {content.map((item, index) => (
              <li
                className={cx(classes.item, 'relative mb-12 lg:mb-0 flex')}
                key={item.title}
              >
                <BrandShape
                  className={cx(
                    classes.itemShape,
                    'relative',
                    'block',
                    'text-bparts-100',
                    'h-12',
                    'w-12'
                  )}
                />
                <div>
                  <h3 className={cx('text-2xl', 'font-bold', 'leading-8')}>
                    {item.title}
                  </h3>
                  <p className="text-base font-[300]">
                    {
                      formatElements(item.description, {
                        b: <b />,
                      }) as ReactNode
                    }
                  </p>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </section>
      <Curve className="h-4 lg:h-6 max-lg:scale-x-invert lg:scale-y text-neutral-10 " />
    </section>
  );
};

export default AboutUs;
