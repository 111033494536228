// LEGACY
import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { forwardRef, FunctionComponent, memo, useState } from 'react';

import IconEdit from '../icons/edit';
import BPImage from '../image';

import classes from './vehicle-preview.module.css';

const logoSizes: {
  [key: string]: {
    px: number;
    css: string;
  };
} = {
  small: {
    px: 20,
    css: 'w-6 lg:w-6',
  },
  medium: {
    px: 32,
    css: 'w-8 lg:w-8',
  },
  default: {
    px: 50,
    css: 'w-8 lg:w-12',
  },
};

const textSizes = {
  small: {
    container: 'leading-5',
    brand: 'text-sm leading-3',
    model: 'text-xs leading-3',
    version: 'text-xs leading-3',
    years: 'text-xs leading-3',
    doors: 'text-xs leading-3',
    stock: 'text-xs leading-3',
  },
  default: {
    container: 'leading-5',
    brand: 'text-base leading-5',
    model: 'text-sm leading-5',
    version: 'text-sm leading-5',
    years: 'text-sm leading-5',
    doors: 'text-sm leading-5',
    stock: 'text-sm leading-5',
  },
  big: {
    container: 'leading-5',
    brand: 'lg:text-2xl font-bold leading-5',
    model: 'lg:text-2xl font-bold leading-5',
    version: 'text-sm leading-5',
    years: 'text-sm leading-5',
    doors: 'text-sm leading-5',
    stock: 'text-sm leading-5',
  },
};

const VehicleSelectionModal = dynamic(
  () => import('../../modules/vehicle-seletion-modal'),
  {
    ssr: false,
  }
);

const VehiclePreview: FunctionComponent<TVehiclePreview> = ({
  allowEdit = false,
  ...vehicleProps
}) => {
  const [afterFirstRenderOpen, setAfterFirstRenderOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return allowEdit ? (
    <>
      <span
        className={cx(
          'grid',
          classes.withEdit,
          'items-start',
          'cursor-pointer'
        )}
        role="button"
        onClick={() => {
          setAfterFirstRenderOpen(true);
          setIsOpen(true);
        }}
      >
        <span>
          <VehicleInfo {...vehicleProps} />
        </span>
        <IconEdit className={cx('h-4 w-4 ml-4 mt-5 lg:mt-6')} />
      </span>
      {afterFirstRenderOpen && (
        <VehicleSelectionModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  ) : (
    <VehicleInfo {...vehicleProps} />
  );
};

const RenderLogo: FunctionComponent<{
  logo?: TVehiclePreview['info']['brand']['logo_url'];
  logo_white?: TVehiclePreview['info']['brand']['logo_white_url'];
  alt: string;
  displayRules: TVehiclePreview['logoDisplay'];
  sizeLogo: TVehiclePreview['sizeLogo'];
}> = ({ logo, logo_white, alt, displayRules, sizeLogo = 'default' }) => {
  return (
    <>
      <span className={cx('hidden', 'lg:block')}>
        {/* eslint-disable-next-line jsx-a11y/alt-text*/}
        <BPImage
          src={displayRules.desktop === 'default' ? logo : logo_white}
          alt={alt}
          width={logoSizes[sizeLogo].px}
          height={logoSizes[sizeLogo].px}
          layout="responsive"
        />
      </span>
      <span className={cx('lg:hidden')}>
        <BPImage
          src={displayRules.mobile === 'default' ? logo : logo_white}
          alt={alt}
          width={logoSizes[sizeLogo].px}
          height={logoSizes[sizeLogo].px}
          layout="responsive"
        />
      </span>
    </>
  );
};

const VehicleInfo: FunctionComponent<TVehiclePreview> = memo(
  forwardRef(
    (
      {
        info,
        showStock = false,
        showLogo = true,
        infoDisplay = {
          brand: 'inline',
          model: 'inline',
          version: 'inline',
          total_products: 'inline',
          years: 'inline',
          doors: 'inline',
          stock: 'inline',
        },
        logoDisplay = {
          desktop: 'default',
          mobile: 'default',
        },
        modeSelect = false,
        centerLogoSelect = false,
        sizeLogo = 'default',
        gapSize = 'default',
        sizeText = 'default',
        logoPosition = 'center',
        highlightDoors = true,
        overflowText = false,
        as: As = 'section',
      },
      ref
    ) => {
      const { t } = useTranslation();

      infoDisplay = {
        brand: 'inline',
        model: 'inline',
        version: 'inline',
        total_products: 'inline',
        years: 'inline',
        doors: 'inline',
        stock: 'inline',
        ...infoDisplay,
      };

      return (
        <div
          ref={ref}
          className={cx(
            {
              grid: !modeSelect,
            },
            classes.vehiclePreviewGrid,
            { 'items-top': logoPosition == 'top' },
            { 'items-center': logoPosition == 'center' },
            {
              'flex flex-row-reverse w-full': modeSelect,
            }
          )}
        >
          {showLogo && false && (
            <div
              className={cx(
                {
                  'mr-4': !modeSelect && gapSize === 'default',
                },
                {
                  'mr-2': !modeSelect && gapSize === 'small',
                },
                logoSizes[sizeLogo].css,
                {
                  'mt-2': logoPosition == 'top',
                },
                { '-mt-4': centerLogoSelect }
              )}
            >
              <RenderLogo
                logo={info.brand.logo_url}
                logo_white={info.brand.logo_white_url}
                alt={info.brand.name}
                displayRules={logoDisplay}
                sizeLogo={sizeLogo}
              />
            </div>
          )}
          <As
            className={cx(
              { [classes.overflowText]: overflowText },
              {
                'grow text-left': modeSelect,
              },
              textSizes[sizeText].container
            )}
          >
            <span
              className={cx(
                {
                  [textSizes[sizeText].brand]:
                    (!infoDisplay.brand || infoDisplay.brand === 'break') &&
                    !info?.model?.name,
                },
                {
                  [textSizes[sizeText].model]:
                    (!infoDisplay.brand || infoDisplay.brand === 'inline') &&
                    info?.model?.name,
                },
                'inline',
                { 'mr-1': info?.model?.name },
                'font-bold',
                ''
              )}
            >
              {info.brand.name}
            </span>
            {infoDisplay.brand === 'break' && info?.model?.name && <br />}

            {info?.model?.name && (
              <>
                {' '}
                <span
                  className={cx(
                    textSizes[sizeText].model,
                    'inline mr-1',
                    'font-light',
                    ''
                  )}
                >
                  {info?.model?.name}
                </span>
                {infoDisplay.model === 'break' && <br />}
              </>
            )}
            {info?.version?.name && (
              <>
                {' '}
                <span
                  className={cx(
                    textSizes[sizeText].version,
                    'inline mr-1',
                    'font-light',
                    ''
                  )}
                >
                  {info?.version?.name}
                </span>
              </>
            )}
            {infoDisplay.version === 'break' && info?.years && <br />}
            {info?.years && (
              <>
                {' '}
                <small
                  className={cx(
                    textSizes[sizeText].years,
                    'inline mr-1',
                    'font-light',
                    '',
                    'whitespace-no-wrap'
                  )}
                >
                  {info?.years}
                </small>
                {infoDisplay.years === 'break' && <br />}
              </>
            )}
            {info?.doors && (
              <>
                {' '}
                <span
                  className={cx(
                    textSizes[sizeText].doors,
                    'inline mr-1',
                    'font-light',
                    '',
                    'whitespace-no-wrap',
                    { 'text-brand-100': highlightDoors },
                    'font-bold'
                  )}
                >
                  ({info?.doors} {t('vehicle:doors')})
                </span>
                {infoDisplay.doors === 'break' && <br />}
              </>
            )}
            {showStock && info?.total_products && (
              <>
                {' '}
                <span
                  className={cx(
                    textSizes[sizeText].stock,
                    'hidden',
                    'lg:block',
                    'font-light',
                    '',
                    'inline-block'
                  )}
                >
                  {t('common:categories.stock.other', {
                    count: info.total_products,
                  })}
                </span>
                {infoDisplay.stock === 'break' && <br />}
              </>
            )}
          </As>
        </div>
      );
    }
  )
);

VehicleInfo.displayName = 'VehicleInfo';

export default VehiclePreview;
