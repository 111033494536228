import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';

import useLinkProps from '../../../hooks/use-link-props';
import { categoryCounterReducer } from '../../../utils/category-counter-reduce';
import { useAppContext } from '../../providers/app-context';
import BPImage from '../../ui/v2/image';
import FeatureLink from '../../ui/links/featured';

import classes from './categories.module.css';
import FeaturedLink from '../../ui/links/featured';

const Support: FunctionComponent<{ groups: TCategories }> = ({ groups }) => {
  const { locale } = useRouter();
  const {
    features: { isBrandEnabled },
    brand,
  } = useAppContext();
  const { t } = useTranslation();
  const getLink = useLinkProps();

  const totals = useMemo(() => categoryCounterReducer(groups), [groups]);

  return (
    <section className={cx('overflow-hidden')} data-cy="categories">
      <div className={cx('py-6', 'lg:py-12')}>
        <div className="container m-auto text-center">
          <div className="pb-6">
            <h2 className="text-2xl font-bold">{t('home:categories.title')}</h2>
            <p className="text-base font-light">{t('home:categories.text')}</p>
          </div>

          <ul
            className={cx(
              'flex flex-wrap gap-6 px-6 justify-between lg:justify-center'
            )}
          >
            {groups.map((category, index) => {
              if (category.id === 3) return null;

              let total = 0;

              return (
                <li
                  data-line={Math.round(index / 2)}
                  key={category.name}
                  className={cx(classes.card, 'group', 'relative')}
                >
                  <div
                    className={cx(
                      'text-white hover:text-white after:bg-white',
                      'aspect-square',
                      'block lg:whitespace-nowrap 2xl:whitespace-normal overflow-hidden overflow-ellipsis w-full'
                    )}
                  >
                    <BPImage
                      src={`${process.env.PUBLIC_PREFIX}/img/categories/${category.id}.jpg`}
                      className={cx('w-full z-0 object-cover')}
                      alt={category.name}
                      fill
                      sizes="100%"
                    />
                    <Link
                      {...getLink('sub-categories', {
                        category: category.slug,
                      })}
                      passHref
                      legacyBehavior
                    >
                      <div
                        className={cx(
                          'absolute',
                          'w-full',
                          'h-full',
                          'text-left',
                          'text-white',
                          'flex',
                          'flex-col',
                          'justify-end'
                        )}
                      >
                        <div
                          className={cx(
                            'relative overflow-hidden z-[2] h-full',
                            'px-1.5 pb-2.5 lg:px-4 lg:pb-4',
                            'flex flex-col justify-end cursor-pointer',
                            'transition-all duration-150 ease-out',
                            'before:absolute before:z-[1]',
                            'before:transition-all before:duration-300 before:ease-out',
                            'before:top-[25%] group-hover:before:top-[80%] before:right-0 before:bottom-0 before:left-0',
                            'before:bg-gradient-to-b before:from-transparent before:to-neutral-80'
                          )}
                        >
                          <h3 className="group-hover:block text-xl relative !z-[2] text-wrap">
                            {category.name}
                          </h3>
                          <span className="relative z-[2]">
                            {t('home:categories.number.other', {
                              count:
                                totals[category.slug].toLocaleString(locale),
                            })}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Support;
