import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import BPImage from '../../image';

import CategoryMask from '../svg-image/just-image';

import classes from './header.module.css';

type CategoryHeaderProps = {
  index: number;
  name: string;
  stock?: number;
  parts?: number;
  id?: number;
  active?: boolean;
  subCategory?: TPart;
  imageSize?: '80' | '64';
  isSideMenu?: boolean;
  hideImage?: boolean;
  infoAlign?: 'row' | 'column';
};

const CategoryHeader: FunctionComponent<CategoryHeaderProps> = ({
  index,
  name,
  stock,
  parts,
  id,
  active,
  subCategory,
  imageSize = '80',
  isSideMenu = false,
  hideImage = false,
  infoAlign = 'row',
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        'flex',
        'items-center',
        'text-base',
        'font-light',
        'text-left',
        active && 'text-brand-100'
      )}
    >
      {!hideImage && (
        <BPImage
          src={`${process.env.PUBLIC_PREFIX}/img/categories/${id}.jpg`}
          className={cx(
            'shrink-0',
            'z-0',
            { 'w-20 h-20': imageSize == '80' },
            { 'w-16 h-16': imageSize == '64' }
          )}
          alt={t('common:images_alt.category_alt', { category: name })}
          width={imageSize == '80' ? 80 : 64}
          height={imageSize == '80' ? 80 : 64}
        />
      )}

      <div
        className={cx(
          'flex',
          'ml-4',
          isSideMenu || infoAlign === 'column'
            ? 'flex-col items-start gap-[6px]'
            : 'flex-row items-end gap-[10px]'
        )}
      >
        <h3 className="text-lg font-bold">{name}</h3>
        {active ? (
          <small>{subCategory.name}</small>
        ) : (
          <>
            {parts && (
              <small>{t('common:categories.parts', { count: parts })}</small>
            )}
            {typeof stock === 'number' && (
              <small>
                {t('common:categories.stock.other', {
                  count: stock.toLocaleString(locale),
                })}
              </small>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryHeader;
