import Head from 'next/head';
import { useRouter } from 'next/router';
import getT from 'next-translate/getT';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';

import usePageViewEvent from '../../../hooks/use-page-view-event';
import useHreflang from '../../../utils/seo/hreflang';
import useOGMeta from '../../../utils/seo/ogmeta';
import { useAppContext } from '../../providers/app-context';

const HomeSchema: FunctionComponent = () => {
  const { locale = 'en' } = useRouter();
  const { t } = useTranslation();
  const { businessValues } = useAppContext();

  const localePath = locale !== 'en' ? `/${locale}` : '';

  const name = t('home:head.title');

  const og = useOGMeta({
    title: t('home:head.title'),
    description: t('home:head.description', {
      totalStockAvailable: businessValues.totalStockAvailable.defaultValue,
    }),
    url: process.env.NEXT_PUBLIC_HOST + localePath,
  });

  const alternateLinks = useHreflang({ locale, route: 'index' });

  usePageViewEvent(name);

  return (
    <Head>
      <title>{name}</title>
      <meta
        name="description"
        key="description"
        content={t('home:head.description', {
          totalStockAvailable: businessValues.totalStockAvailable.defaultValue,
        })}
      />
      {alternateLinks}
      {og}

      <script
        {...jsonLdScriptProps({
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'Organization',
              '@id': `${process.env.NEXT_PUBLIC_HOST}${localePath}#organization`, // Website  + #organization(required)
              name: t('home:head.title'), // (Dynamic per language)
              description: t('home:head.description', {
                totalStockAvailable:
                  businessValues.totalStockAvailable.defaultValue,
              }), // (Dynamic per language)
              url: `${process.env.NEXT_PUBLIC_HOST}${localePath}`,
              sameAs: [
                'https://www.facebook.com/bparts.oficial',
                'https://www.linkedin.com/company/b-parts',
                'https://www.youtube.com/channel/UCnrCh4mywViLpOJ9SVaxc5w',
                'https://twitter.com/bparts_oficial',
                'https://www.instagram.com/bparts_official',
              ],
              logo: {
                '@type': 'ImageObject',
                '@id': `${process.env.NEXT_PUBLIC_HOST}${localePath}#logo`,
                url: 'https://s3-eu-west-1.amazonaws.com/bparts-eu/logo/b-parts-brand-logo.png', // The image must be 112x112px, at minimum. The image URL must be crawlable and indexable.
                caption: 'B-Parts',
              },
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  telephone: '+351 220 327 775',
                  email: 'geral@b-parts.com',
                  contactType: 'Customer Service',
                  availableLanguage: 'Portuguese',
                  url: `${process.env.NEXT_PUBLIC_HOST}/pt/contactos`,
                },
                {
                  '@type': 'ContactPoint',
                  telephone: '+34 911 23 59 00',
                  email: 'hola@b-parts.com',
                  contactType: 'Customer Service',
                  availableLanguage: 'Spanish',
                  url: `${process.env.NEXT_PUBLIC_HOST}/es/contactos`,
                },
                {
                  '@type': 'ContactPoint',
                  telephone: '+49 32 221850013',
                  contactType: 'Customer Service',
                  availableLanguage: 'German',
                  url: `${process.env.NEXT_PUBLIC_HOST}/de/kontakte`,
                },
                {
                  '@type': 'ContactPoint',
                  telephone: '+33 1 76 44 05 62',
                  contactType: 'Customer Service',
                  availableLanguage: 'French',
                  url: `${process.env.NEXT_PUBLIC_HOST}/fr/contacts`,
                },
                {
                  '@type': 'ContactPoint',
                  telephone: '+44 2046 006762',
                  contactType: 'Customer Service',
                  availableLanguage: 'English',
                  url: `${process.env.NEXT_PUBLIC_HOST}/en/contacts`,
                },
              ],
            },
          ],
        })}
      />
    </Head>
  );
};

export default HomeSchema;
