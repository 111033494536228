import { FunctionComponent, HTMLAttributes } from 'react';

const IconEdit: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M16.7538 5.97193V15.0304C16.7538 15.289 16.7029 15.545 16.6039 15.784C16.5049 16.0229 16.3599 16.24 16.177 16.4228C15.9942 16.6057 15.7771 16.7507 15.5382 16.8497C15.2993 16.9487 15.0432 16.9996 14.7846 16.9996H2.96923C2.44695 16.9996 1.94607 16.7921 1.57677 16.4228C1.20747 16.0535 1 15.5526 1 15.0304V3.21501C1 2.69274 1.20747 2.19186 1.57677 1.82256C1.94607 1.45326 2.44695 1.24579 2.96923 1.24579H11.2144"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8048 1.2524C16.7346 1.17521 16.6494 1.11307 16.5545 1.06973C16.4595 1.02638 16.3568 1.00274 16.2524 1.00022C16.1481 0.997708 16.0443 1.01637 15.9474 1.05509C15.8505 1.0938 15.7624 1.15177 15.6885 1.22548L15.0938 1.81725C15.0217 1.88937 14.9812 1.98715 14.9812 2.08911C14.9812 2.19106 15.0217 2.28884 15.0938 2.36096L15.639 2.90514C15.6747 2.94104 15.7172 2.96953 15.764 2.98897C15.8108 3.00841 15.8609 3.01842 15.9116 3.01842C15.9622 3.01842 16.0124 3.00841 16.0592 2.98897C16.106 2.96953 16.1484 2.94104 16.1842 2.90514L16.764 2.32827C17.0572 2.0355 17.0846 1.55862 16.8048 1.2524ZM13.8914 3.01908L5.21263 11.6818C5.16001 11.7342 5.12176 11.7993 5.10157 11.8707L4.70013 13.0663C4.69052 13.0987 4.68984 13.1332 4.69816 13.166C4.70649 13.1988 4.72351 13.2287 4.74744 13.2526C4.77137 13.2766 4.80131 13.2936 4.83411 13.3019C4.86691 13.3102 4.90134 13.3096 4.93379 13.2999L6.12849 12.8985C6.19995 12.8783 6.26502 12.8401 6.31743 12.7875L14.9808 4.10841C15.061 4.0274 15.1059 3.91806 15.1059 3.80411C15.1059 3.69016 15.061 3.58081 14.9808 3.4998L14.5025 3.01908C14.4213 2.9382 14.3115 2.89278 14.1969 2.89278C14.0824 2.89278 13.9725 2.9382 13.8914 3.01908Z"
      fill="currentcolor"
    />
  </svg>
);

export default IconEdit;
