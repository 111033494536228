type TgenerateLink = {
  slug: TVehicleSelection;
  partSlug: string;
  categorySlug: string;
  getLink: any;
  reference?: string | undefined;
};

const generateLink = ({
  getLink,
  slug,
  partSlug,
  categorySlug,
  reference,
}: TgenerateLink) => {
  if (reference) {
    return getLink('reference', {
      reference: reference!,
      ...(categorySlug &&
        partSlug && {
          category: categorySlug,
          subCategory: partSlug,
        }),
      ...(slug && { vehicle: slug }),
    });
  } else {
    if (!slug) {
      // part only
      return getLink('brands-with-part', {
        category: categorySlug,
        subCategory: partSlug,
      });
    }

    return getLink('products', {
      category: categorySlug,
      subCategory: partSlug,
      vehicle: slug,
    });
  }
};

export default generateLink;
