/* eslint-disable jsx-a11y/role-supports-aria-props */
import cx from 'classnames';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { useDisableFocus } from '../../../providers/disable-focus';
import Chevrelon from '../../icons/cheverlon';
import IconPlus from '../../icons/plus';

import Collapsable from './collapsable';
import classes from './collapsible-list.module.css';

type CollapsibleListProps = {
  items: Array<{
    header: ReactNode;
    content: ReactNode;
    key: string | number;
    disabled?: boolean;
    onOpen?: Function;
  }>;
  withGap: boolean;
  icon?: 'arrow' | 'plus';
  openAllResults?: boolean;
};

const getIcon = (type: CollapsibleListProps['icon'], isActive: boolean) => {
  switch (type) {
    case 'plus':
      return <IconPlus isMinus={isActive} className={cx('shrink-0', 'w-4')} />;
    case 'arrow':
    default:
      return (
        <Chevrelon
          className={cx(
            classes.icon,
            'shrink-0',
            'w-4',
            {
              [classes.active]: isActive,
            },
            'group-hover:text-brand-100',
            'group-active:text-brand-100',
            'group-focus:text-brand-100'
          )}
        />
      );
  }
};

const CollapsibleList: FunctionComponent<CollapsibleListProps> = ({
  items,
  withGap = true,
  icon = 'arrow',
  openAllResults = false,
}) => {
  const [open, setOpen] = useState<number | undefined>(undefined);
  const disableFocus = useDisableFocus();

  return (
    <ul>
      {items.map(
        ({ header, content, key, disabled = false, onOpen }, index) => {
          const id = `question-${index}`;
          const active = index === open;
          const openList = index === open || openAllResults;

          if (disabled) {
            return (
              <li
                key={key}
                tabIndex={-1}
                className={cx(
                  index < items.length - 1 ? 'border-b border-neutral-30' : ''
                )}
              >
                <div className={cx('px-4 py-4', 'opacity-50')}>{header}</div>
              </li>
            );
          }

          return (
            <li
              key={key}
              className={cx(
                index < items.length - 1 ? 'border-b border-neutral-30' : ''
              )}
            >
              <button
                type="button"
                className={cx(
                  'text-base',
                  'font-bold',
                  'min-w-full',
                  'flex',
                  'justify-between',
                  'px-4',
                  { 'py-4': withGap },
                  'cursor-pointer',
                  'items-center',
                  'focus:outline-none',
                  { 'text-brand-100 focus:text-neutral-80': active },
                  'focus:bg-brand-20',
                  'hover:bg-brand-20',
                  'group'
                )}
                tabIndex={disableFocus ? -1 : 0}
                onClick={() => {
                  setOpen((curr) => {
                    if (index === curr) {
                      return;
                    } else {
                      if (onOpen) onOpen();
                      return index;
                    }
                  });
                }}
                role="presentation"
                aria-expanded={openList}
                aria-controls={id}
                data-cy="collapsible-button"
              >
                <div>{header}</div>

                {getIcon(icon, openList)}
              </button>

              <Collapsable
                id={id}
                content={content}
                active={openList}
                closeCollapsable={() => setOpen(undefined)}
              />
            </li>
          );
        }
      )}
    </ul>
  );
};

export default CollapsibleList;
