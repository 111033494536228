import cx from 'classnames';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useIsMobile from '../../../hooks/use-is-mobile';

import useTranslation from 'next-translate/useTranslation';
import Button from '../../ui/button';
import Collapsable from '../../ui/collapsible-list/menu/collapsable';
import Chevrelon from '../../ui/icons/cheverlon';
import IconClose from '../../ui/icons/close';
import Sticky from 'react-sticky-el';
import SearchInput from '../v2/vehicle/search-input';
import classes from './categories-list.module.css';
import { useRouter } from 'next/router';
import Link from 'next/link';
import generateLink from '../../ui/v2/categories/navigation/generate-link';
import MenuLink from '../../ui/links/menu';
import PartNameWithStock from '../../ui/v2/categories/common/part-name-with-stock';
import useLinkProps from '../../../hooks/use-link-props';
import { Translate } from 'next-translate';
import CollapsibleList from '../../ui/collapsible-list/menu';
import CategoryHeader from '../../ui/categories/header';
import { getFilteredGroups, regexExpress } from '../../../utils/search-results';

const ListSeparator = ({
  id,
  inColumns,
  t,
  locale,
}: {
  id: number;
  inColumns: boolean;
  t: Translate;
  locale?: string;
}) => {
  const groupName = useMemo(() => {
    switch (id) {
      case 1:
        return t('header:groups.group-1');
      case 2:
        return t('header:groups.group-2');
      case 3:
        return t('header:groups.group-3');
      default:
        return;
    }
  }, [id, t]);

  return (
    <li
      role="separator"
      className={cx(
        classes.columnSpan,
        { [classes.splitInColumns]: inColumns },
        'text-[18px]',
        'pl-4',
        'text-bparts-100',
        'text-left'
      )}
    >
      {groupName}
    </li>
  );
};

const CategoriesListAndSearchSection: FunctionComponent<{
  groupsSort: TCategories;
  hideCategoriesList: boolean;
  isOpen: boolean;
  splitInColumns?: boolean;
  searchProps: { search: string; setSearch: any };
}> = ({
  groupsSort,
  hideCategoriesList = false,
  isOpen = false,
  splitInColumns = true,
  searchProps,
}) => {
  const { t } = useTranslation();
  const { locale = 'en' } = useRouter();
  const [openAllResults, setOpenAllResults] = useState<boolean>(false);
  const getLink = useLinkProps();

  const filteredGroups = useMemo(() => {
    setOpenAllResults(searchProps.search ? true : false);

    return getFilteredGroups(searchProps.search, groupsSort);
  }, [searchProps.search, groupsSort]);

  const regex = useMemo(() => {
    return regexExpress(searchProps.search);
  }, [searchProps.search]);

  const partname = useCallback(
    (name: string) => {
      return name.split(regex).map((value, index, original) => {
        if (index < original.length - 1) {
          return (
            <Fragment key={value + index}>
              {value}
              <span key={value + index} className="font-bold text-brand-100">
                {name.match(regex)?.[0]}
              </span>
            </Fragment>
          );
        }

        return <Fragment key={value + index}>{value}</Fragment>;
      });
    },
    [regex]
  );

  return (
    <div
      className={cx('hybridCategoryNavigation mx-auto container', {
        '[&_.sticky]:hidden': !isOpen && hideCategoriesList,
      })}
    >
      <Sticky
        mode="top"
        topOffset={-48}
        boundaryElement=".hybridCategoryNavigation"
        hideOnBoundaryHit={false}
        wrapperClassName="max-lg:px-4 w-full mx-auto container"
        stickyClassName={cx(
          isOpen && hideCategoriesList ? '!top-12 z-10 bg-white ' : 'hidden'
        )}
        dontUpdateHolderHeightWhenSticky={true}
      >
        <SearchInput
          search={searchProps.search}
          placeholder={t('common:filterByPart')}
          setSearch={searchProps.setSearch}
          isFullWith={true}
          targetId={'scroll_to_search_categories'}
        />
      </Sticky>
      <section
        className={cx(
          'max-lg:hidden',
          'container',
          'm-auto',
          'flex flex-col w-full'
        )}
      >
        {filteredGroups.map((elements, index) => {
          const parts = elements.parts;
          const categorySlug = elements.slug;
          return (
            <>
              <section
                className={cx(
                  'flex flex-col w-full',
                  index < filteredGroups.length - 1
                    ? 'border-b border-neutral-30'
                    : '',
                  'pb-3 my-3 first:mt-6'
                )}
              >
                <div className="flex flex-row items-baseline gap-2">
                  <h2 className="text-[18px] font-extrabold">
                    {elements.name}
                  </h2>
                  <span className="text-sm">
                    {elements.total_products.toLocaleString(locale)}{' '}
                    {t('common:categories.label.subCategory.other')}
                  </span>
                </div>
                <div className="relative grid">
                  <ul className={cx({ [classes.list]: splitInColumns })}>
                    {Object.entries(parts).map(([group_id, g]) => (
                      <React.Fragment key={`${categorySlug}-${group_id}`}>
                        {parseInt(group_id, 10) > 0 && (
                          <ListSeparator
                            id={parseInt(group_id, 10)}
                            inColumns={splitInColumns}
                            t={t}
                            locale={locale}
                          />
                        )}
                        {g.map(
                          ({
                            id,
                            name,
                            slug: partSlug,
                            total_products: totalPartProducts,
                          }: TPart) => {
                            return (
                              <li key={id} className="w-full inline-block">
                                <Link
                                  {...generateLink({
                                    getLink,
                                    slug: '',
                                    partSlug,
                                    categorySlug,
                                  })}
                                  passHref
                                  shallow
                                  prefetch={false}
                                  legacyBehavior
                                  className="pointer-events-none"
                                  itemProp="category"
                                >
                                  <MenuLink disabled={totalPartProducts <= 0}>
                                    <PartNameWithStock
                                      name={partname(name)}
                                      slug={partSlug}
                                      display={
                                        splitInColumns ? 'inline' : 'column'
                                      }
                                      nameTextTag={'h4'}
                                    />
                                  </MenuLink>
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </section>
            </>
          );
        })}
      </section>

      <section className="lg:hidden px-4 pt-6">
        <CollapsibleList
          items={filteredGroups?.map(
            (
              { id, name, parts, slug: categorySlug, total_products },
              index
            ) => ({
              header: (
                <CategoryHeader
                  index={index}
                  id={id}
                  name={name}
                  stock={total_products}
                  parts={parts.length}
                  hideImage={true}
                  infoAlign={'column'}
                />
              ),
              content: (
                <>
                  <ul className={cx({ [classes.list]: splitInColumns })}>
                    {Object.entries(parts).map(([group_id, g]) => (
                      <React.Fragment key={`${categorySlug}-${group_id}`}>
                        {parseInt(group_id, 10) > 0 && (
                          <ListSeparator
                            id={parseInt(group_id, 10)}
                            inColumns={splitInColumns}
                            t={t}
                            locale={locale}
                          />
                        )}
                        {g.map(
                          ({
                            id,
                            name,
                            slug: partSlug,
                            total_products: totalPartProducts,
                          }: TPart) => {
                            return (
                              <li key={id} className="w-full inline-block">
                                <Link
                                  {...generateLink({
                                    getLink,
                                    slug: '',
                                    partSlug,
                                    categorySlug,
                                  })}
                                  passHref
                                  shallow
                                  prefetch={false}
                                  legacyBehavior
                                  className="pointer-events-none"
                                  itemProp="category"
                                >
                                  <MenuLink disabled={totalPartProducts <= 0}>
                                    <PartNameWithStock
                                      name={partname(name)}
                                      slug={partSlug}
                                      display={
                                        splitInColumns ? 'inline' : 'column'
                                      }
                                      nameTextTag={'h4'}
                                    />
                                  </MenuLink>
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </>
              ),
              key: id,
              disabled: total_products <= 0,
            })
          )}
          withGap={true}
          openAllResults={openAllResults}
        />
      </section>
    </div>
  );
};

const CategoriesList: FunctionComponent<{
  groupsSort: TCategories;
  hideCategoriesList?: boolean;
  defaultisOpenValue?: boolean;
}> = ({
  groupsSort,
  hideCategoriesList = false,
  defaultisOpenValue = false,
}) => {
  const [search, setSearch] = useState<string>('');
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [isOpen, setisOpen] = useState<boolean>(defaultisOpenValue);

  useEffect(() => {
    setisOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <section
        className={cx({ 'pb-6': !isOpen })}
        id="scroll_to_search_categories"
      >
        {hideCategoriesList ? (
          <>
            <section className="flex items-center">
              <span
                className={cx(
                  'w-full flex flex-col mx-auto text-center items-center justify-center lg:pb-6'
                )}
              >
                <b className="text-xl font-bold lg:pb-3">
                  {t('home:categories.pre-link')}
                </b>
                <div className="categoriesList w-full lg:px-4">
                  <Sticky
                    mode="top"
                    boundaryElement=".categoriesList"
                    hideOnBoundaryHit={false}
                    stickyClassName="z-10"
                  >
                    <div className="w-full h-12 bg-white flex flex-col mx-auto pt-2 text-center items-center justify-center">
                      <Button
                        variant="ROUNDED"
                        onClick={() => {
                          if (isOpen) {
                            setSearch('');
                          }
                          setisOpen(!isOpen);
                          const element =
                            document.querySelector('.categoriesList');
                          element?.scrollIntoView({ behavior: 'instant' });
                        }}
                        className={cx(
                          'transition-all duration-300 ease-in-out',
                          'overflow-hidden border-b border-neutral-30 last-of-type:border-0',
                          'flex flex-row items-center justify-center gap-2 !px-4 !py-2',
                          isOpen
                            ? '!bg-neutral-80 !text-white'
                            : '!text-bparts-100'
                        )}
                        isFullWidth={false}
                        disableHeight={true}
                      >
                        <span className="font-bold text-[18px]">
                          {t('categories:searchAllCategories')}
                        </span>
                        {isOpen ? (
                          <IconClose
                            className={cx(
                              'w-4',
                              'transition-transform duration-300 ease-in-out text-white'
                            )}
                          ></IconClose>
                        ) : (
                          <Chevrelon
                            className={cx(
                              'w-4',
                              'transition-transform duration-300 ease-in-out text-bparts-100'
                            )}
                          ></Chevrelon>
                        )}
                      </Button>
                    </div>
                  </Sticky>
                  <Collapsable
                    id={'CategoriesListAndSearchSection'}
                    content={
                      <CategoriesListAndSearchSection
                        groupsSort={groupsSort}
                        isOpen={isOpen}
                        hideCategoriesList={hideCategoriesList}
                        splitInColumns={true}
                        searchProps={{ search, setSearch }}
                      ></CategoriesListAndSearchSection>
                    }
                    active={isOpen}
                  />
                </div>
              </span>
            </section>
          </>
        ) : (
          <CategoriesListAndSearchSection
            groupsSort={groupsSort}
            isOpen={isOpen}
            hideCategoriesList={hideCategoriesList}
            splitInColumns={true}
            searchProps={{ search, setSearch }}
          ></CategoriesListAndSearchSection>
        )}
      </section>
    </>
  );
};

export default CategoriesList;
