import { escapeRegExp } from './escapeRegExp';

export const regexExpress = (search: string) => {
  return new RegExp(escapeRegExp(`${search}`), 'ig');
};

export const getFilteredGroups = (search: string, groupsSort: TCategories) => {
  if (search) {
    const filtered = groupsSort
      .map((category) => ({
        ...category,
        parts: Object.keys(category.parts).reduce((acc, key) => {
          const finalArray = category.parts[key].filter((part) => {
            return part.name.match(new RegExp(escapeRegExp(search), 'i'));
          });

          if (finalArray.length === 0) {
            return acc;
          }

          return {
            [key]: finalArray,
            ...acc,
          };
        }, {}),
      }))
      .filter(
        (category) =>
          Object.values(category.parts).filter((p) => {
            return p.length > 0;
          }).length > 0
      );

    return filtered;
  }

  return groupsSort;
};
