import cx from 'classnames';
import { useAtom } from 'jotai';
import { ComponentProps, forwardRef, ReactNode } from 'react';

import { navigationOpen } from '../../../../state/navigation';
import { useDisableFocus } from '../../../providers/disable-focus';
import IconCheck from '../../icons/check';

import classes from './menu-link.module.css';

type DefaultProps = {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  color?: 'DEFAULT' | 'BLUE' | 'RED';
  bold?: boolean;
  disableFocusOnMenu?: boolean;
  active?: boolean;
  noLink?: boolean;
  otherClassNameProps?: any;
  disabled?: boolean;
};

type AnchorProps = DefaultProps & {
  as?: 'a';
} & ComponentProps<'a'>;

type ButtonProps = DefaultProps & {
  as?: 'button';
} & ComponentProps<'button'>;

const MenuLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps | AnchorProps
>(
  (
    {
      as: Component = 'a',
      leftIcon,
      active,
      rightIcon,
      children,
      color = 'DEFAULT',
      bold = false,
      disableFocusOnMenu,
      noLink = false,
      otherClassNameProps = '',
      disabled = false,
      ...props
    },
    ref
  ) => {
    const disableFocus = useDisableFocus();
    const [menuOpen] = useAtom(navigationOpen);
    const hasNoFocus = disableFocus || (disableFocusOnMenu && menuOpen);

    let componentChildren = children;
    if (leftIcon || rightIcon || active) {
      componentChildren = (
        <>
          {leftIcon && leftIcon}
          <div className="flex flex-1">{children}</div>
          {(active && <IconCheck className="h-3 w-3" />) ||
            (rightIcon && rightIcon)}
        </>
      );
    }

    const FinalComponent = noLink ? 'div' : Component;

    return (
      <FinalComponent
        ref={ref}
        tabIndex={hasNoFocus ? -1 : 0}
        className={cx(
          'grid',
          'grid-rows-1',
          'px-4',
          'h-12',
          'w-full',
          { 'cursor-pointer': !noLink },
          { 'text-brand-100': color === 'BLUE' },
          { 'text-red-100': color === 'RED' },
          { 'font-bold': bold },
          'items-center',
          'focus:outline-none',
          { 'focus:bg-brand-20': !noLink },
          { 'hover:bg-brand-20': !noLink },
          active ? 'font-bold' : 'font-ligth',
          { [classes.link]: leftIcon || rightIcon || active },
          otherClassNameProps ? otherClassNameProps : 'gap-4',
          {
            'pointer-events-none': disabled,
          },
        )}
        {...props}
      >
        {componentChildren}
      </FinalComponent>
    );
  }
);

MenuLink.displayName = 'MenuLink';

export default MenuLink;
