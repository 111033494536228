import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';

import { originalRoutes } from '../../../../../hooks/use-link-props';
import getGroupsWithStocks from '../../../../../utils/react-query/query-options/get-groups-with-stock';
import IconCheck from '../../../icons/check';
import { useAtom } from 'jotai';
import { countersFinal } from '../../../../../hooks/use-group-list';

type PartNameWithStockProps = {
  name: ReactNode;
  slug: string;
  display?: 'column' | 'inline';
  inverted?: boolean;
  isChecked?: boolean;
  nameTextTag?: keyof JSX.IntrinsicElements;
};

const PartNameWithStock = ({
  name,
  slug,
  display = 'inline',
  inverted = false,
  isChecked = false,
  nameTextTag: As = 'span',
}: PartNameWithStockProps) => {
  const {
    locale = 'en',
    pathname,
    query: { category, subCategory, vehicle, reference },
  } = useRouter();
  const [, setCounters] = useAtom(countersFinal);

  const vehicleToSearch = useMemo(() => {
    if (pathname == originalRoutes['categories-with-vehicle']) {
      return category as string;
    }

    if (
      [originalRoutes['reference'], originalRoutes['products']].includes(
        pathname
      )
    ) {
      return vehicle;
    }

    if (pathname == originalRoutes['sub-categories-with-vehicle']) {
      //in this case the category is actually the vehicle
      return category;
    }

    return null;
  }, [pathname, category, vehicle]);

  const { data: counters } = useQuery(
    ...getGroupsWithStocks({
      locale,
      ...(vehicleToSearch ? { car_identifier: vehicleToSearch as string } : {}),
      ...(reference ? { reference: reference as string } : {}),
    })
  );

  const { total_products } = useMemo(() => {
    if (counters) {
      setCounters(counters);
      return counters
        .reduce((allParts, category) => {
          return [
            ...allParts,
            ...Object.values(category.parts).reduce(
              (partsForCat, part) => [...partsForCat, ...part],
              []
            ),
          ];
        }, [])
        .find((part) => part.slug === slug) as TPart;
    } else {
      return { total_products: null };
    }
  }, [counters, slug]);

  return (
    <div
      className={cx('flex items-center', {
        'text-neutral-50': total_products === 0,
        'font-bold': !inverted,
        'flex justify-between items-center': display === 'column',
      })}
    >
      <As className="text-left w-[80%]">{name}</As>
      {total_products !== null && (
        <span
          className={cx('text-xs', 'relative', 'ml-2', {
            'font-normal': !inverted,
            'font-bold': inverted,
            'flex gap-4': isChecked,
          }, 'flex w-[20%] max-lg:justify-end')}
        >
          {isChecked && (
            <IconCheck className="h-4 w-4 justify-self-end text-brand-100" />
          )}
          {total_products.toLocaleString(locale)}
        </span>
      )}
    </div>
  );
};

export default PartNameWithStock;
