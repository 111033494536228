import cx from 'classnames';

type CurveProps = { className?: string, variantProps?: any }

const Curve = ({ className }: CurveProps) => {
  return (
    <svg
      viewBox="0 0 48 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={cx('w-full transform', className)}
    >
      <path d="M0 0H48V24L0 0Z" fill="currentcolor" />
    </svg>
  );
};

export default Curve;
