import { useQueries } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useMemo } from 'react';

import { fetchFaqs } from '../../../services/faqs';
import { fetchGroups } from '../../../services/groups';
import { fetchBrands } from '../../../services/vehicles';
import Reviews from '../../modules/reviews';
import ValueProposition from '../../modules/value-proposition';
import { useAppContext } from '../../providers/app-context';

import AboutUs from './about-us';
import Banner from './banner';
import Categories from './categories';
import Questions from './questions';
import HomeSchema from './seo';
import Curve from '../../ui/curve';
import BrandList from '../vehicle/brands/brand-list';
import useIsMobile from '../../../hooks/use-is-mobile';
import CategoriesList from '../categories/categories-list';

const TopBrands = dynamic(() => import('./top-brands'));

const Home: FunctionComponent<TPageHome> = ({ groupsSort }) => {
  const { locale, route } = useRouter();
  const isMobile = useIsMobile();
  const {
    features: { isBrandEnabled },
  } = useAppContext();
  const [{ data: groups }, { data: brands }, { data: faqs }] = useQueries({
    queries: [
      {
        queryKey: ['groups', locale],
        queryFn: ({ queryKey: [, locale] }) => fetchGroups({ locale }),
        staleTime: 10000,
        keepPreviousData: true,
      },
      {
        queryKey: ['brands', locale],
        queryFn: ({ queryKey: [, locale] }) => fetchBrands({ locale }),
        staleTime: 10000,
        keepPreviousData: true,
        enabled: isBrandEnabled,
      },
      {
        queryKey: ['faqs', locale, 'home'],
        queryFn: ({ queryKey: [, locale, slug] }) =>
          fetchFaqs({ locale, slug }),
        staleTime: 10000,
        keepPreviousData: true,
      },
    ],
  });

  const brandList = useMemo(() => {
    if (brands) {
      return brands.children?.filter((b) => b.logo_url && b.logo_white_url);
    }

    return [];
  }, [brands]);

  const defaultisOpenValue = useMemo(() => {
    return !isMobile;
  }, [isMobile]);

  return (
    <main>
      <HomeSchema />
      <Banner />
      <ValueProposition variant="SECONDARY" isHome={true} />
      <Curve className="h-4 lg:h-6 scale-y text-neutral-10 " />
      <Categories groups={groups} groupsSort={groupsSort} />
      <CategoriesList
        groupsSort={groupsSort}
        hideCategoriesList={true}
        defaultisOpenValue={defaultisOpenValue}
      ></CategoriesList>
      <AboutUs />
      <TopBrands brands={brandList} />
      <BrandList
        brands={brands?.children}
        searchByPlateAvailable={false}
        isScrollToTopAvailable={false}
        hideBrandList={true}
        defaultisOpenValue={defaultisOpenValue}
        savedCarsAvailable={false}
      />
      <Questions faqs={faqs} />
      <Reviews />
    </main>
  );
};

export default Home;
