import { IncomingMessage } from 'http';

import { QueryFunctionContext, QueryKey } from '@tanstack/react-query';

import { fetchGroups, fetchGroupsWithStocks } from '../../../services/groups';
import { getGroupsWithStockKey } from '../query-keys';

const getGroupsWithStocks = (
  options: {
    locale: string;
    reference?: string;
    bp_reference?: string;
    min_price?: string;
    max_price?: string;
    car_identifier?: string;
  },
  req?: IncomingMessage
): [
  QueryKey,
  (options: QueryFunctionContext) => ReturnType<typeof fetchGroups>
] => [
  getGroupsWithStockKey(options),
  ({
    queryKey: [
      ,
      locale,
      reference,
      bp_reference,
      min_price,
      max_price,
      car_identifier,
    ],
  }: QueryFunctionContext<string[], any>): Promise<TCategories> =>
    fetchGroupsWithStocks(
      { locale, reference, bp_reference, min_price, max_price, car_identifier },
      req
    ),
];

export default getGroupsWithStocks;
