import { QueryFunction, QueryKey } from '@tanstack/react-query';

import fetchMyCars from '../../../services/user/user-cars';
import { getSaveUserCarKeys } from '../query-keys';

const getUserSavedCars = (options: {
  locale: string;
  csrftoken: string;
}): [QueryKey, QueryFunction<TCarCompleteInfo[]>] => [
  getSaveUserCarKeys(options),
  ({ queryKey: [, , locale, csrftoken] }) => {
    return fetchMyCars({
      locale: locale as string,
      csrftoken: csrftoken as string,
    });
  },
];

export default getUserSavedCars;
